import React, { useRef, useState, useEffect } from "react"

import useAutosizeTextArea from "./useAutosizeTextArea.jsx";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { byPrefixAndName } from '@awesome.me/kit-7ab6be0559/icons'

import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import ListItemText from '@mui/material/ListItemText';
import Checkbox from '@mui/material/Checkbox';

export default function Input({ disableInput, onSend, startNewChat, guideMe, handleFileChange, fileList, fileUploaded, deleteFile, setSourceDB }) {
    const [value, setValue] = useState("")
    const textAreaRef = useRef(null)
    const [dbSource, setDBSource] = useState(['All'])

    const handleSend = e => {
        e.preventDefault();
        onSend(value)
        setValue("")
    }

    const onEnterPress = e => {
        if (e.code === "Enter" && e.shiftKey == false) {
            console.log("Enter key was pressed. Run your function.");
            e.preventDefault();
            handleSend(e);
        }
    }

    const handleChange = evt => {
        const val = evt.target?.value

        setValue(val)
    }

    const handleOptionChange = async (event) => {
        console.log("A")
        const {
            target: { value },
        } = event;
        setSourceDB(
            // On autofill we get a stringified value.
            typeof value === 'string' ? value.split(',') : value,
        )
        setDBSource(
            // On autofill we get a stringified value.
            typeof value === 'string' ? value.split(',') : value,
        );

    };

    useAutosizeTextArea(textAreaRef.current, value)

    const sourceCategories = [
        'All',
        'Legislation and supporting material',
        'Public rulings',
        'Practical compliance guidelines',
        'Cases',
        'Decision impact statements',
        'ATO interpretative decisions',
        'Law administration practice statements',
        'Taxpayer alerts',
        "SMSF Regulators Bulletins",
        'Other ATO documents',
        'ATO law aids',
        'Edited private advice',
        'Archived document types'
    ];

    return (
        <>
            <div className={`abacus-input-header`}>
                <button className={`abacus-input-new-chat-btn `} onClick={() => startNewChat()}>
                    New Chat
                </button>
                <button className={`abacus-input-new-chat-btn `} onClick={() => guideMe()}>
                    Guide Me
                </button>
                {fileUploaded ? (
                    <button className={`abacus-input-new-chat-btn files`} onClick={deleteFile}>
                        Delete Uploads
                    </button>
                ) : (

                    <>
                        <label for='input-file' className="abacus-input-upload-label">
                            <p className={`abacus-input-new-chat-btn files`}>
                                Upload Files
                            </p>
                        </label>
                    </>
                )}
                <div className="source-button-container">
                    <FormControl id="abacus-souce-options" sx={{ m: 1, minWidth: 120 }}>
                        {dbSource == "" ? <InputLabel id="abacus-souce-options-input">No Sources</InputLabel>
                            :
                            <InputLabel id="abacus-souce-options-input">Sources</InputLabel>}

                        <Select
                            labelId="abacus-souce-options-label"
                            id="abacus-souce-options-select"
                            value={dbSource}
                            onChange={handleOptionChange}
                            label="Sources"
                            input={<OutlinedInput label="Sources" />}
                            multiple
                            renderValue={(selected) => selected.join(', ')}
                        >
                            {sourceCategories.map((source) => (
                                <MenuItem id="abacus-souce-options-item" key={source} value={source}>
                                    <Checkbox id="abacus-souce-options-item-checkbox" checked={dbSource.includes(source)} />
                                    <ListItemText id="abacus-souce-options-item-text" primary={source} />
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </div>
            </div >
            <div className="abacus-input-container">
                <form onSubmit={handleSend} className={`abacus-input-box ${disableInput}`}>
                    <textarea
                        ref={textAreaRef}
                        rows={1}
                        value={value}
                        placeholder="Ask your question..."
                        onChange={handleChange}
                        onKeyDown={onEnterPress}
                        disabled={disableInput}
                    ></textarea>
                    <input readonly={disableInput} id='input-file' multiple type="file" onChange={handleFileChange} />
                    <button className={`abacus-input-enter-chat-btn `} onClick={(e) => handleSend(e)}>
                        Submit
                    </button>
                </form >
                {fileUploaded ? (
                    <div className="abacus-uploaded-files">
                        {fileList.map((file) =>
                            <div className="abacus-uploaded-file">
                                <p>
                                    {file}
                                </p>
                            </div>
                        )}
                    </div>
                ) : (<></>)}
            </div>
        </>
    )
}
