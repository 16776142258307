'use client';
import { useCallback, useRef, useState, useEffect } from 'react';
import '../styles/App.css';
import { BrowserRouter, Route, Switch } from "react-router-dom";
import nuwAILogo from '../assets/NuwAI@2x.png';
import { AuthenticatedTemplate, UnauthenticatedTemplate } from "@azure/msal-react";
import { ReactComponent as SettingsIcon } from '../assets/settings.svg'
import { ReactComponent as UploadIcon } from '../assets/upload.svg'
import { ReactComponent as SaveIcon } from '../assets/save.svg'
import { ReactComponent as TrashIcon } from '../assets/trash-alt.svg'

import { ReactComponent as DownloadIcon } from '../assets/file-pdf.svg'
import { ReactComponent as ArrowRight } from '../assets/arrow-alt-circle-right.svg'
import { ReactComponent as ArrowLeft } from '../assets/arrow-alt-circle-left.svg'
import { ReactComponent as Close } from '../assets/times-circle.svg'
import { ReactComponent as EnterInputIcon } from '../assets/enter-input.svg'
import SignInSignOutButton from "../components/ui/signInLogoutButton";
import useAutosizeTextArea from "../useAutosizeTextArea";

import NavBar from "../components/ui/NavBar"
import Launch from "../components/ui/Launch"
import NewChat from "../components/ui/NewChat"

import API from "../components/bot/chatBot";
import "react-pdf/dist/esm/Page/AnnotationLayer.css"
import "react-pdf/dist/esm/Page/TextLayer.css"

import BotMessage from "../components/bot/botMessage";
import UserMessage from "../components/bot/userMessage";

import Input from "../components/bot/userInput";

import { styled } from '@mui/material/styles';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import ToggleSwitch from '@mui/material/Switch';
import Snackbar from '@mui/material/Snackbar';
import Tooltip from '@mui/material/Tooltip';

import LoginScreen from "./login"

import apiConfig from "../config/api";

import { loginRequest } from "../config/msalConfig";


import { callMsGraph, callMsGraphTenant } from "../utils/MsGraphApiCall";

import { MsalAuthenticationTemplate, useMsal } from "@azure/msal-react";
import { InteractionStatus, InteractionType, InteractionRequiredAuthError } from "@azure/msal-browser";

import { BlobServiceClient, StorageSharedKeyCredential } from "@azure/storage-blob"

import { Document, Page } from 'react-pdf';

import { pdfjs } from 'react-pdf';
import ReactGA from "react-ga4";

import backend_api from "../utils/BackendApiCaller"

import { v4 as uuidv4 } from 'uuid';
import useWindowDimensions from "./useWindowDimensions";
const containerName = `${apiConfig.blobStore[process.env.REACT_APP_BLOB_STORE]}`

const blobServiceClient = new BlobServiceClient(`https://nuwaiato.blob.core.windows.net/?sv=2022-11-02&ss=b&srt=sco&sp=rl&se=2024-12-30T17:40:35Z&st=2024-08-12T10:40:35Z&spr=https&sig=nWdsj%2B6s9AnzNfRxXI2kHfI%2B3gb%2FeG7IxtMyUZ93IgU%3D`);

const SettingSwitch = styled((props) => (
  <ToggleSwitch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
  width: 40,
  height: 24,
  padding: 0,
  '& .MuiSwitch-switchBase': {
    padding: 0,
    margin: 2,
    transitionDuration: '300ms',
    '&.Mui-checked': {
      transform: 'translateX(16px)',
      color: '#fff',
      '& + .MuiSwitch-track': {
        opacity: 1,
        border: 0,
      },
      '&.Mui-disabled + .MuiSwitch-track': {
        opacity: 0.5,
      },
    },
    '&.Mui-focusVisible .MuiSwitch-thumb': {
      color: '#33cf4d',
      border: '6px solid #fff',
    },
    '&.Mui-disabled .MuiSwitch-thumb': {
      color:
        theme.palette.mode === 'light'
          ? theme.palette.grey[100]
          : theme.palette.grey[600],
    },
    '&.Mui-disabled + .MuiSwitch-track': {
      opacity: theme.palette.mode === 'light' ? 0.7 : 0.3,
    },
  },
  '& .MuiSwitch-thumb': {
    boxSizing: 'border-box',
    width: 20,
    height: 20
  },
  '& .MuiSwitch-track': {
    borderRadius: 26 / 2,
    backgroundColor: '#E0E0E0',
    opacity: 1,
    transition: theme.transitions.create(['background-color'], {
      duration: 500,
    }),
  },
}));

let nextId = 0;

pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@4.3.136/build/pdf.worker.min.mjs`;

// pdfjs.GlobalWorkerOptions.workerSrc = new URL(
//   'pdfjs-dist/build/pdf.worker.min.mjs',
//   import.meta.url,
// ).toString();

const resizeObserverOptions = {}
const maxWidth = 750

const options = {
  cMapUrl: "/cmaps/",
  standardFontDataUrl: "/standard_fonts/"
}

export function Home() {
  const [readyToLaunch, setReadyToLaunch] = useState(true)
  const [showAI, setShowAI] = useState(false)
  const [hideLaunch, setHideLaunch] = useState(false)

  const [inputValue, setInputValue] = useState("")
  const [settingsMenuPosition, setSettingsMenuPosition] = useState("-600px")
  const [settingsMenuWidth, setSettingsMenuWidth] = useState("0px")
  const [inputHeight, setInputHeight] = useState("65px")
  const [messages, setMessages] = useState([]);
  const textAreaRef = useRef(null)
  const [fileUploaded, setFileUploaded] = useState(false)
  const [fileList, setFile] = useState([])
  const [blobName, setBlobName] = useState("");

  const [blobAzure, setBlobAzure] = useState("")
  const [numPages, setNumPages] = useState(0);
  const [pageNumber, setPageNumber] = useState(1);
  const [containerWidth, setContainerWidth] = useState()
  const [viewPDF, setViewPDF] = useState("")
  const [blobTester, setBlobTester] = useState(false)

  const [aiModel_nuwai, setaiModel_nuwai] = useState(false);
  const [aiModel_gpt, setaiModel_gpt] = useState(true);
  const [aiModel_gemini, setaiModel_gemini] = useState(false);
  const [active_aiModel, setActiveAiModel] = useState("gpt");

  const [aiTone_simple, setTone_simple] = useState(false);
  const [aiTone_advisor, setTone_advisor] = useState(false);
  const [active_aiTone, setActiveAiTone] = useState("");

  const divRef = useRef(null);

  const [snackText, setSnackText] = useState("")
  const [open, setOpen] = useState(false);
  const [state, setState] = useState({
    vertical: 'bottom',
    horizontal: 'center',
  });
  const { vertical, horizontal } = state;
  const [apiType, setApiType] = useState("normal")
  const [enableReferences, setReferences] = useState(false);
  const bottomChat = useRef(null)
  const [count, setCount] = useState(1)
  const [aiHistory, setAiHistory] = useState([])

  const [showProfile, setShowProfile] = useState(false);
  const { instance, inProgress, accounts } = useMsal();
  const [graphData, setGraphData] = useState(null)
  const [userProfile, setUserProfile] = useState({})
  const [graphTenantData, setGraphTenantData] = useState(null)
  const [apiData, setApiData] = useState(null);
  const [statusResponse, setStatusResponse] = useState(false)
  const [sessionUID, setSessionUID] = useState("")

  const [profileLoaded, setProfileLoaded] = useState(false)
  const [firstName, setFirstName] = useState("")
  const [lastName, setLastName] = useState("")
  const [userInitials, setUserInitials] = useState("")
  const [profileSettings, setProfileSettings] = useState()
  const [disableInput, setDisableInput] = useState(false);

  const [guideMeState, setGuideMe] = useState(false);
  const [newSession, setNewSession] = useState(true);
  const [hidePrompt, setHidePrompt] = useState(false)

  const [sourceDB, setSourceDB] = useState(["All"])

  const accessTokenRequestAPI = {
    scopes: [`${process.env.REACT_APP_ENTRA_APP_API}`],
    account: accounts[0],
  };

  useAutosizeTextArea(textAreaRef.current, inputValue);

  const openSettings = evt => {
    if (settingsMenuPosition == "-600px") {
      setSettingsMenuPosition("40px")
      setSettingsMenuWidth("265px")
    } else {
      setSettingsMenuPosition("-600px")
      setSettingsMenuWidth("0px")
    }
  }

  const handleChange = evt => {
    const val = evt.target?.value
    setInputValue(val)
  }

  const handleFileChange = async e => {
    e.preventDefault();
    if (e.target.files) {
      setFileUploaded(true);
      const formData = new FormData();
      var fileList = []
      for (var x = 0; x < e.target.files.length; x++) {
        console.log("File " + x + " " + e.target.files[x].name)
        formData.append('file', e.target.files[x], e.target.files[x].name);

        fileList.push(e.target.files[x].name)

      }
      setFile(fileList)
      var response = ""

      const accessTokenRequestAPI = {
        scopes: [`${process.env.REACT_APP_ENTRA_APP_API}`],
        account: accounts[0],
      };

      instance
        .acquireTokenSilent(accessTokenRequestAPI)
        .then(async (accessTokenResponse) => {
          // Acquire token silent success
          let accessToken = accessTokenResponse.accessToken;
          console.log(accessToken)
          // Call your API with token
          response = await API.UploadFiles(formData, accessToken, graphTenantData, sessionUID);

          setAiHistory([...aiHistory, {
            id: count,
            user_id: `${count}.${count}`,
            session_id: sessionUID,
            model: "",
            question: "",
            references: "",
            response: response,
            source_select: sourceDB,
            sources: "",
            latest: true,
            tone: "",
            type: "status",
            inputTokens: 0,
            outputTokens: 0,
            totalTokens: 0
          }])
          setCount(count + 1);
          // callApi(accessToken).then((response) => {
          //   setApiData(response);
          // });
        })
        .catch((error) => {
          if (error instanceof InteractionRequiredAuthError) {
            instance.acquireTokenRedirect(accessTokenRequestAPI);
          }
          console.log(error);
        });
    }
  }

  const deleteFile = e => {
    e.preventDefault();

    const accessTokenRequestAPI = {
      scopes: [`${process.env.REACT_APP_ENTRA_APP_API}`],
      account: accounts[0],
    };
    var response = ""

    instance
      .acquireTokenSilent(accessTokenRequestAPI)
      .then(async (accessTokenResponse) => {
        // Acquire token silent success
        let accessToken = accessTokenResponse.accessToken;
        console.log(accessToken)
        // Call your API with token
        response = await backend_api.deleteFile(accessToken, graphTenantData, sessionUID);

        setFile([])
        setFileUploaded(false);
        setAiHistory([...aiHistory, {
          id: count,
          session_id: sessionUID,
          model: "",
          question: "",
          references: "",
          response: "Uploaded files have been deleted.",
          source_select: sourceDB,
          sources: "",
          latest: true,
          tone: "",
          type: "status",
          inputTokens: 0,
          outputTokens: 0,
          totalTokens: 0
        }])
        setCount(count + 1);
      })
      .catch((error) => {
        if (error instanceof InteractionRequiredAuthError) {
          instance.acquireTokenRedirect(accessTokenRequestAPI);
        }
        console.log(error);
      });
  }

  const updateAiResponse = async (responseObject, id) => {
    console.log("Update AI Response", responseObject)
    setAiHistory(aiHistory => aiHistory.map(option => option.id === id
      ? { ...option, response: responseObject.response, sources: responseObject.sources, totalTokens: responseObject.total_tokens, inputTokens: responseObject.input_tokens, outputTokens: responseObject.output_tokens }
      : option
    ));
  }

  const downloadPDF = async (fileName, location) => {
    const blobURL = await getBlobURL(fileName, location)

    const link = document.createElement('a');
    link.href = blobURL;
    link.setAttribute('download', `${blobName}`);
    document.body.appendChild(link);
    link.click();
    link.parentNode.removeChild(link);

    // clean up Url
    window.URL.revokeObjectURL(blobURL);
  }

  const getBlobURL = async (fileName, location) => {
    setBlobName(fileName)

    const containerClient = blobServiceClient.getContainerClient(containerName);
    const blobClient = containerClient.getBlobClient(location);
    const downloadBlockBlobResponse = await blobClient.download();

    console.log(downloadBlockBlobResponse)

    const downloaded = await blobToString(await downloadBlockBlobResponse.blobBody);

    async function blobToString(blob) {
      const fileReader = new FileReader();
      return new Promise((resolve, reject) => {
        fileReader.onloadend = (ev) => {
          resolve(ev.target.result);
        };
        fileReader.onerror = reject;
        fileReader.readAsText(blob);
      });
    }

    const newBlob = new Blob([await downloadBlockBlobResponse.blobBody], { type: "application/pdf" });

    const blobUrl = URL.createObjectURL(newBlob);

    return blobUrl
  }

  const newAiHistory = async (question) => {
    if (readyToLaunch) {
      setLaunchBody(false)
    }

    console.log("Count is at ", count);
    if (question != "" || question != null) {
      setAiHistory([...aiHistory, {
        id: count,
        user_id: `${count}.${count}`,
        session_id: sessionUID,
        model: active_aiModel,
        question: question,
        references: enableReferences,
        response: "",
        source_select: sourceDB,
        sources: "",
        latest: true,
        tone: active_aiTone,
        type: "message",
        inputTokens: 0,
        outputTokens: 0,
        totalTokens: 0
      }])
      setCount(count + 1);
      if (count != 1) {
        var num = count - 1;
        setAiHistory(aiHistory => aiHistory.map(option => option.id === num
          ? { ...option, latest: false }
          : option
        ));
        // divRef.current.scrollIntoView({ block: "end", behavior: "smooth" })
      }
      // divRef.current.scrollIntoView({ behavior: 'smooth' })
      console.log("Count is now at ", count);
    } else {
      handleClick("Invalid question or question format, Please try again.");
    }

  }

  const restoreAIHistory = async (previousHistory) => {
    setDisableInput(true)
    var new_local_history = []
    if (previousHistory.history.length > 0) {
      for (var x = 0; x < previousHistory.history.length; x++) {
        new_local_history.push({
          id: x,
          user_id: `${x}.${x}`,
          session_id: previousHistory.history[x].session_id,
          model: "gpt",
          question: previousHistory.history[x].question,
          references: previousHistory.history[x].references,
          response: previousHistory.history[x].response,
          source_select: previousHistory.history[x].source_select,
          sources: previousHistory.history[x].source,
          latest: previousHistory.history[x].latest,
          tone: previousHistory.history[x].tone,
          type: "message",
          inputTokens: 0,
          outputTokens: 0,
          totalTokens: 0
        })
      }
      setAiHistory(new_local_history)
    } else {
      new_local_history.push({
        id: x,
        user_id: `${x}.${x}`,
        session_id: "",
        model: "",
        question: "No data in chat history",
        references: "",
        response: "",
        source_select: sourceDB,
        sources: "",
        latest: "",
        tone: "",
        type: "message",
        inputTokens: 0,
        outputTokens: 0,
        totalTokens: 0
      })
      setAiHistory(new_local_history)
    }
  }

  const handleClick = (text) => {
    setSnackText(text)
    setOpen(true);
  };

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      setOpen(false);
    }
  };

  const aiModel_change = (event) => {
    var model_name = event.target.name;
    var checkedState = event.target.checked;

    console.log(model_name)
    console.log(checkedState)
    if (model_name == "nuwai") {
      if (checkedState == false && aiModel_gpt == false && aiModel_gemini == false) {
        console.log("Not allowed")
      } else {
        setaiModel_nuwai(checkedState);
        setaiModel_gemini(false);
        setaiModel_gpt(false);
        setActiveAiModel(model_name)
      }
    } else if (model_name == "gpt") {
      if (checkedState == false && aiModel_nuwai == false && aiModel_gemini == false) {
        console.log("Not allowed")
      } else {
        setaiModel_nuwai(false);
        setaiModel_gpt(checkedState);
        setaiModel_gemini(false);
        setActiveAiModel(model_name)
      }
    } else if (model_name == "gemini") {
      if (checkedState == false && aiModel_gpt == false && aiModel_nuwai == false) {
        console.log("Not allowed")
      } else {
        setaiModel_nuwai(false);
        setaiModel_gpt(false);
        setaiModel_gemini(checkedState);
        setActiveAiModel(model_name)
      }
    }
  };

  const aiTone_change = (event) => {
    var tone_name = event.target.name;
    var checkedState = event.target.checked;

    if (tone_name == "simple") {
      setTone_simple(checkedState);
      setTone_advisor(false);
      if (checkedState) {
        setApiType("simplify")
        setActiveAiTone(tone_name)
      } else {
        setApiType("normal")
        setActiveAiTone('')
      }
    } else if (tone_name == "tax-advisor") {

      setTone_simple(false);
      setTone_advisor(checkedState);

      if (checkedState) {
        setApiType("tax-advisor")
        setActiveAiTone(tone_name)
      } else {
        setApiType("normal")
        setActiveAiTone('')
      }
    }
  };

  const otherSettings_change = (event) => {
    var setting_name = event.target.name;
    var checkedState = event.target.checked;

    if (setting_name == "references") {
      setReferences(checkedState);
    }
  };

  const AlwaysScrollToBottom = () => {
    console.log("Scrolling to bottom");
    const elementRef = useRef();
    console.log("ElementRef ", elementRef);
    useEffect(() => elementRef.current.scrollIntoView());
    return <div ref={elementRef} />;
  };

  const ApiStatusCheck = async () => {
    var api_response = await backend_api.apiStatus()
    setStatusResponse(api_response.Status)
    return api_response
  }

  const startNewChat = async () => {
    var sessionUID = uuidv4();
    console.log(sessionUID)

    instance
      .acquireTokenSilent(accessTokenRequestAPI)
      .then(async (accessTokenResponse) => {
        console.log("Acquired Silent Token")
        // Acquire token silent success
        let accessToken = accessTokenResponse.accessToken;
        console.log(accessToken)
        try {
          await backend_api.addOrganisation(accessToken, graphTenantData, userProfile, sessionUID)

          setShowAI(false)
          setHidePrompt(false)
          setNewSession(true)
          setSessionUID(sessionUID)
          setCount(1)
          setAiHistory([])
          getHistory()
          setDisableInput(false)
        } catch (err) {
          console.log(err)
        }

      })
      .catch((error) => {
        if (error instanceof InteractionRequiredAuthError) {
          instance.acquireTokenRedirect(accessTokenRequestAPI);
        }
        console.log(error);
      });
  }

  const guideMe = async () => {
    var local_chatData = {
      "session_id": sessionUID,
      "rag_question_state": false
    }
    instance
      .acquireTokenSilent(accessTokenRequestAPI)
      .then(async (accessTokenResponse) => {
        console.log("Acquired Silent Token")
        // Acquire token silent success
        let accessToken = accessTokenResponse.accessToken;
        console.log(accessToken)
        try {
          await backend_api.updateChatState(accessToken, graphTenantData, local_chatData)

          setAiHistory([...aiHistory, {
            id: count,
            user_id: `${count}.${count}`,
            session_id: sessionUID,
            model: "",
            question: "",
            references: "",
            response: "I will now help you structure and refine your next question to ensure I provide an accurate response that understatnds your context and situation. Please ask your question.",
            source_select: sourceDB,
            sources: "",
            latest: true,
            tone: "",
            type: "status",
            inputTokens: 0,
            outputTokens: 0,
            totalTokens: 0
          }])
          setCount(count + 1);

        } catch (err) {
          console.log(err)
        }

      })
      .catch((error) => {
        if (error instanceof InteractionRequiredAuthError) {
          instance.acquireTokenRedirect(accessTokenRequestAPI);
        }
        console.log(error);
      });
  }

  const [thisWeekHistory, setThisWeekHistory] = useState([])

  const getWeekNumber = (d) => {
    // Copy date so don't modify original
    d = new Date(Date.UTC(d.getFullYear(), d.getMonth(), d.getDate()));
    // Set to nearest Thursday: current date + 4 - current day number
    // Make Sunday's day number 7
    d.setUTCDate(d.getUTCDate() + 4 - (d.getUTCDay() || 7));
    // Get first day of year
    var yearStart = new Date(Date.UTC(d.getUTCFullYear(), 0, 1));
    // Calculate full weeks to nearest Thursday
    var weekNo = Math.ceil((((d - yearStart) / 86400000) + 1) / 7);
    // Return array of year and week number
    return weekNo;
  }

  const getHistory = async () => {
    const today = new Date();
    var weekOfYear = getWeekNumber(today)
    console.log(weekOfYear)

    instance
      .acquireTokenSilent(accessTokenRequestAPI)
      .then(async (accessTokenResponse) => {
        let accessToken = accessTokenResponse.accessToken;
        var user_history = await backend_api.getHistory(accessToken, graphTenantData)
        var thisWeek_local = []
        for (var x = 0; x < user_history.data.length; x++) {
          if (user_history.data[x].r.question_week == weekOfYear) {
            if (user_history.data[x].r.session_id != sessionUID) {
              thisWeek_local.push(user_history.data[x].r)
            }
          }
        }
        setThisWeekHistory(thisWeek_local)
      })
      .catch((error) => {
        if (error instanceof InteractionRequiredAuthError) {
          instance.acquireTokenRedirect(accessTokenRequestAPI);
        }
        console.log(error);
      });
  }

  const { width } = useWindowDimensions();

  useEffect(() => {
    console.log('rending home')
    console.log("WIDTH ", width)
    // setDims({ w: widthDebounc-150 });
    // setDims(window.innerWidth)
    // divRef.current?.scrollIntoView({ behavior: 'smooth' })
    // ReactGA.send({
    //   hitType: "pageview",
    //   page: "/",
    //   title: "Home"
    // })

    console.log(process.env.REACT_APP_ENTRA_APP_API)

    const accessTokenRequestAPI = {
      scopes: [`${process.env.REACT_APP_ENTRA_APP_API}`],
      account: accounts[0],
    };

    const fetchProfile = async () => {
      if (statusResponse && !graphData && graphData == null && inProgress === InteractionStatus.None) {
        console.log("Calling MS Graph API")
        callMsGraph()
          .then(response => {
            var userDetails = {
              "jobTitle": `${response.jobTitle}`,
              "mobilePhone": `${response.mobilePhone}`,
            }
            console.log("Calling MS Graph Tenant API")

            var firstnameInitial = Array.from(response.givenName)[0];
            var lastnameInitial = Array.from(response.surname)[0];

            var givenName = response.givenName
            var surname = response.surname

            callMsGraphTenant()
              .then(response => {
                var tenantDetails = {
                  "tid": response.value[0].id,
                  "tdn": response.value[0].displayName
                }

                console.log("Calling Instance and Getting Silent Token")

                instance
                  .acquireTokenSilent(accessTokenRequestAPI)
                  .then(async (accessTokenResponse) => {
                    console.log("Acquired Silent Token")
                    // Acquire token silent success
                    let accessToken = accessTokenResponse.accessToken;
                    console.log(accessToken)
                    // console.log(accessToken)

                    var sessionUID = uuidv4();
                    console.log(sessionUID)

                    try {
                      await backend_api.addOrganisation(accessToken, tenantDetails, userDetails, sessionUID)
                      const profile_details = await backend_api.getProfile(accessToken, tenantDetails)

                      switch (profile_details.data.profile_settings.system_color) {
                        case "blue":
                          document.body.classList.add('blue');
                          break;
                        case "red":
                          document.body.classList.add('red');
                          break;
                        case "yellow":
                          document.body.classList.add('yellow');
                          break;
                        case "green":
                          document.body.classList.add('green');
                          break;
                      }

                      const today = new Date();
                      var weekOfYear = getWeekNumber(today)

                      var user_history = await backend_api.getHistory(accessToken, tenantDetails)
                      var thisWeek_local = []
                      for (var x = 0; x < user_history.data.length; x++) {
                        if (user_history.data[x].r.question_week == weekOfYear) {
                          if (user_history.data[x].r.session_id != sessionUID) {
                            thisWeek_local.push(user_history.data[x].r)
                          }
                        }
                      }
                      setThisWeekHistory(thisWeek_local)

                      if (!profile_details.data.general.show_startup) {
                        setHideLaunch(profile_details.data.general.show_startup)
                        setShowAI(false)
                        setReadyToLaunch(false)
                      }

                      setSessionUID(sessionUID)
                      setGraphTenantData(tenantDetails)
                      setProfileSettings(profile_details.data)
                      setGraphData(response)
                      setFirstName(givenName)
                      setLastName(surname)
                      setUserInitials(`${firstnameInitial}${lastnameInitial}`)
                      setUserProfile(userDetails)
                      getHistory()
                      setProfileLoaded(true)
                    } catch (err) {
                      console.log(err)
                    }


                  })
                  .catch((error) => {
                    if (error instanceof InteractionRequiredAuthError) {
                      instance.acquireTokenRedirect(accessTokenRequestAPI);
                    }
                    console.log(error);
                  });
              })
              .catch((e) => {
                if (e instanceof InteractionRequiredAuthError) {
                  instance.acquireTokenRedirect({
                    ...loginRequest,
                    account: instance.getActiveAccount()
                  });
                }
              })
          })
          .catch((e) => {
            if (e instanceof InteractionRequiredAuthError) {
              instance.acquireTokenRedirect({
                ...loginRequest,
                account: instance.getActiveAccount()
              });
            }
          });
      }


    }

    ApiStatusCheck()

    fetchProfile()
      .catch(console.error);

  }, [inProgress, accounts, graphData, instance, statusResponse]);

  const closeBlob = async (event) => {
    setViewPDF("")
    setBlobAzure("")
    setBlobTester(false);
  }

  const getBlob = async (fileName, location) => {
    setViewPDF("viewing-pdf")

    setBlobName(fileName)

    const containerClient = blobServiceClient.getContainerClient(containerName);
    const blobClient = containerClient.getBlobClient(location);
    const downloadBlockBlobResponse = await blobClient.download();

    console.log(downloadBlockBlobResponse)

    const downloaded = await blobToString(await downloadBlockBlobResponse.blobBody);

    async function blobToString(blob) {
      const fileReader = new FileReader();
      return new Promise((resolve, reject) => {
        fileReader.onloadend = (ev) => {
          resolve(ev.target.result);
        };
        fileReader.onerror = reject;
        fileReader.readAsText(blob);
      });
    }

    const newBlob = new Blob([await downloadBlockBlobResponse.blobBody]);

    const blobUrl = window.URL.createObjectURL(newBlob);

    setBlobAzure(newBlob)
  }

  const onDocumentLoadSuccess = ({ numPages: nextNumPages }) => {
    setNumPages(nextNumPages)
  }

  const onResize = useCallback(entries => {
    const [entry] = entries

    if (entry) {
      setContainerWidth(entry.contentRect.width)
    }
  }, [])

  const goToNextPage = () => {
    setPageNumber((prevPageNumber) => prevPageNumber + 1);
  }

  const goToPreviousPage = () => {
    setPageNumber((prevPageNumber) => prevPageNumber - 1);
  }

  const [drag, setDrag] = useState({
    active: false,
    x: "",
  });

  const [dims, setDims] = useState({
    w: width,
  });

  const [messageWidth, setMessageWidth] = useState(
    {
      maxWidth: `${dims.w}px`,
    }
  )

  const startResize = e => {
    setDrag({
      active: true,
      x: e.clientX,
    });
  };

  const resizeFrame = e => {
    const { active, x } = drag;
    if (active) {
      console.log(x)
      console.log(e.clientX)
      const xDiff = Math.abs(x - e.clientX);
      const newW = x > e.clientX ? dims.w - xDiff : dims.w + xDiff;


      console.log(newW);

      setDrag({ ...drag, x: e.clientX });
      setDims({ w: newW });
      setMessageWidth({ maxWidth: `${dims.w}px` })
    }
  };

  const stopResize = e => {
    setDrag({ ...drag, active: false });
  };

  const setLaunchBody = e => {
    setHideLaunch(true)

    var local_settings = profileSettings

    instance
      .acquireTokenSilent(accessTokenRequestAPI)
      .then(async (accessTokenResponse) => {
        let accessToken = accessTokenResponse.accessToken;
        local_settings.general.show_startup = false;
        await backend_api.updateProfile(accessToken, graphTenantData, local_settings)
      })
      .catch((error) => {
        if (error instanceof InteractionRequiredAuthError) {
          instance.acquireTokenRedirect(accessTokenRequestAPI);
        }
        console.log(error);
      });

    const launchTimeout = setTimeout(() => {
      setReadyToLaunch(false)
    }, 500)
  }

  const startNewSession = e => {
    setHidePrompt(true)
    var local_settings = profileSettings

    instance
      .acquireTokenSilent(accessTokenRequestAPI)
      .then(async (accessTokenResponse) => {
        let accessToken = accessTokenResponse.accessToken;
        local_settings.general.show_startup = false;
        await backend_api.updateProfile(accessToken, graphTenantData, local_settings)
      })
      .catch((error) => {
        if (error instanceof InteractionRequiredAuthError) {
          instance.acquireTokenRedirect(accessTokenRequestAPI);
        }
        console.log(error);
      });

    const launchTimeout = setTimeout(() => {
      setShowAI(true)
      setNewSession(false)
    }, 500)
  }

  const startChat = (chatType) => {
    if (chatType == "guideMe") {
      var local_chatData = {
        "session_id": sessionUID,
        "rag_question_state": false
      }

      instance
        .acquireTokenSilent(accessTokenRequestAPI)
        .then(async (accessTokenResponse) => {
          console.log("Acquired Silent Token")
          // Acquire token silent success
          let accessToken = accessTokenResponse.accessToken;
          console.log(accessToken)
          try {
            await backend_api.updateChatState(accessToken, graphTenantData, local_chatData)

            startNewSession()
          } catch (err) {
            console.log(err)
          }

        })
        .catch((error) => {
          if (error instanceof InteractionRequiredAuthError) {
            instance.acquireTokenRedirect(accessTokenRequestAPI);
          }
          console.log(error);
        });
    }

    if (chatType == "jumpIn") {
      var local_chatData = {
        "session_id": sessionUID,
        "rag_question_state": true
      }

      instance
        .acquireTokenSilent(accessTokenRequestAPI)
        .then(async (accessTokenResponse) => {
          console.log("Acquired Silent Token")
          // Acquire token silent success
          let accessToken = accessTokenResponse.accessToken;
          console.log(accessToken)
          try {
            await backend_api.updateChatState(accessToken, graphTenantData, local_chatData)

            startNewSession()
          } catch (err) {
            console.log(err)
          }

        })
        .catch((error) => {
          if (error instanceof InteractionRequiredAuthError) {
            instance.acquireTokenRedirect(accessTokenRequestAPI);
          }
          console.log(error);
        });

    }
  }

  return (
    <>
      <AuthenticatedTemplate>
        {statusResponse && profileLoaded ?
          <>
            <div className="abacus-ai-body">
              <NavBar messageWidthSetter={setMessageWidth} dimSetter={setDims} profileSettings={profileSettings} graphTenantData={graphTenantData} restoreAIHistory={restoreAIHistory} startNewChat={startNewChat} thisWeekHistory={thisWeekHistory} />
              <div className="abacus-ai-container">
                <div className={`abacus-ai-history-body ${showAI}`} onMouseMove={resizeFrame} onMouseUp={stopResize}>
                  {readyToLaunch ? <Launch setLaunchBody={setLaunchBody} hideLaunch={hideLaunch} firstName={firstName} lastName={lastName} />
                    :
                    newSession ? <NewChat hidePrompt={hidePrompt} startChat={startChat} />
                      :
                      <>
                        <div className="abacus-ai-window" style={width < dims.w ? { maxWidth: width + "px" } : messageWidth}>
                          {aiHistory.map(history => (
                            <>
                              {history.question == "initial" || history.type == "status" ? <></> :
                                <UserMessage key={history.user_id} text={history.question} userInitials={userInitials} />}
                              {history.type != "status" ?
                                history.id == (count - 1) ?
                                  <BotMessage
                                    history={history}
                                    key={history.id}
                                    updateAiResponse={updateAiResponse}
                                    downloadPDF={downloadPDF}
                                    getBlob={getBlob}
                                    getBlobURL={getBlobURL}
                                    fetchMessage={async (accessToken, tenantData, chatData, setMessage, setLoading, setReponseObj) => await API.GetChatbotResponse(apiType, history.question, history.model, history.tone, history.references, history.sources, history.source_select, accessToken, tenantData, chatData, setMessage, setLoading, setReponseObj)}
                                  /> : <BotMessage
                                    history={history}
                                    updateAiResponse={updateAiResponse}
                                    downloadPDF={downloadPDF}
                                    getBlob={getBlob}
                                    getBlobURL={getBlobURL}
                                    key={history.id}
                                  />
                                : <BotMessage
                                  history={history}
                                  updateAiResponse={updateAiResponse}
                                  downloadPDF={downloadPDF}
                                  getBlob={getBlob}
                                  getBlobURL={getBlobURL}
                                  key={history.id}
                                />}

                            </>))}
                          <div id="bottomChat" className="bottomDiv" ref={divRef} />
                        </div>
                        <div className="abacus-ai-sizer-container">
                          <div className="abacus-ai-right-sizer" onMouseDown={startResize} onMouseUp={stopResize}></div>
                        </div>
                      </>
                  }
                </div>
                {readyToLaunch || newSession ? <></>
                  :
                  <div className={`abacus-user-input-container`} style={width < dims.w ? { maxWidth: width + "px" } : messageWidth}>
                    <Input disableInput={disableInput} onSend={newAiHistory} startNewChat={startNewChat} guideMe={guideMe} handleFileChange={handleFileChange} fileList={fileList} fileUploaded={fileUploaded} deleteFile={deleteFile} setSourceDB={setSourceDB} />
                  </div>
                }
              </div>
            </div>
            <div className={`pdf-ai-container ${viewPDF}`}>
              <nav className="ai-pdf-menu">
                <div className="ai-pdf-menu-heading">
                  <h1>
                    {blobName.substring(0, blobName.length - 4)}
                  </h1>
                </div>
                <div className="ai-pdf-menu-options">
                  <Tooltip title="Close PDF">
                    <Close alt="Close PDF" className="ai-pdf-settings close" onClick={closeBlob} />
                  </Tooltip>
                  <Tooltip title="Download PDF">
                    <DownloadIcon alt="Download PDF" className="ai-pdf-settings download" onClick={downloadPDF} />
                  </Tooltip>
                  <Tooltip title="Previous Page">
                    <ArrowLeft alt="Previous Page" className="ai-pdf-settings left" onClick={goToPreviousPage} />
                  </Tooltip>
                  <Tooltip title="Next Page">
                    <ArrowRight alt="Next Page" className="ai-pdf-settings right" onClick={goToNextPage} />
                  </Tooltip>
                  <div className="ai-pdf-page-counter">
                    <span>{pageNumber}</span>
                    <span> / {numPages}</span>
                  </div>
                </div>
              </nav>
              <div className="ai-pdf-viewer">
                <Document
                  file={blobAzure}
                  onLoadSuccess={onDocumentLoadSuccess}
                  options={options}
                  renderMode="canvas"
                >
                  <Page
                    className=""
                    key={pageNumber}
                    pageNumber={pageNumber}
                    renderAnnotationLayer={false}
                    renderTextLayer={false}
                    width={
                      containerWidth ? Math.min(containerWidth, maxWidth) : maxWidth
                    }
                  />
                </Document>
              </div>
            </div>
          </>
          : <div className="bouncing-loader">
            <div></div>
            <div></div>
            <div></div>
          </div>
        }
      </AuthenticatedTemplate>

      <UnauthenticatedTemplate>
        <LoginScreen />
      </UnauthenticatedTemplate>
    </>
  );
}